var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center text-h3"},[_vm._v("All Orders")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('search-input',{attrs:{"default-input":_vm.query},on:{"change":_vm.onQueryChanged}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"no-data-text":"No order found for your search query!","loading-text":"Loading orders...","items":_vm.orders,"options":_vm.datatableOptions,"loading":_vm.loadingOrders,"single-expand":true,"show-expand":"","server-items-length":_vm.totalItems,"items-per-page":_vm.footerProps.itemsPerPage,"footer-props":_vm.footerProps,"item-key":"orderId"},on:{"update:items":function($event){_vm.orders=$event},"update:options":function($event){_vm.datatableOptions=$event}},scopedSlots:_vm._u([{key:"item.customerEmail",fn:function(props){return [_vm._v(" "+_vm._s(props.item.customerEmail)+" "),(props.item.isReferred)?_c('v-btn',{staticClass:"text-none",attrs:{"small":"","to":{
                                        name: _vm.$constants.ROUTE_NAMES
                                            .ADMIN_REFERRALS_INDEX,
                                        query: {
                                            query: props.item.referralCode,
                                        },
                                    },"rounded":"","color":"success"}},[_vm._v(" Referred ")]):_vm._e(),(
                                        props.item.isNewCustomer &&
                                        !props.item.isReferred
                                    )?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v("New")]):_vm._e()]}},{key:"item.createdAtTimestamp",fn:function(props){return [_vm._v(" "+_vm._s(props.item.createdAt.toLocaleDateString())+" "+_vm._s(props.item.createdAt.toLocaleTimeString())+" ")]}},{key:"item.paymentAmount",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("currency")(props.item.paymentAmount, props.item.paymentCurrency ))+" ")]}},{key:"item.dstvPackageName",fn:function(props){return [_vm._v(" "+_vm._s(props.item.dstvPackageName)+" "),_c('order-quantity',{attrs:{"order":props.item}})]}},{key:"item.orderStatus",fn:function(props){return [_c('v-chip',{attrs:{"color":props.item.statusColor,"dark":""}},[_vm._v(" "+_vm._s(_vm._f("titleCase")(props.item.orderStatus))+" ")])]}},{key:"expanded-item",fn:function(ref){
                                    var headers = ref.headers;
                                    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',[_c('h6',{staticClass:"text-h6"},[_vm._v(" More Details ")]),_c('v-spacer'),(item.isStuck)?_c('loading-button',{staticClass:"mr-2",attrs:{"small":true,"color":"error","is-loading":_vm.isRegisteringFailedPayment,"icon":_vm.failedIcon},on:{"click":function($event){return _vm.registerFailedPayment(
                                                            item.orderId
                                                        )}}},[_vm._v(" Failed Payment ")]):_vm._e(),(item.isStuck)?_c('loading-button',{attrs:{"small":true,"color":"success","is-loading":_vm.isRegisteringSuccessfulPayment,"icon":_vm.successIcon},on:{"click":function($event){return _vm.registerSuccessfulPayment(
                                                            item.orderId
                                                        )}}},[_vm._v(" Successfull Payment ")]):_vm._e(),(
                                                        item.needsMaintenance
                                                    )?_c('loading-button',{attrs:{"is-loading":_vm.isMaintaining,"icon":_vm.retryIcon},on:{"click":function($event){return _vm.maintainOrder(
                                                            item.orderId
                                                        )}}},[_vm._v(" Continue Processing ")]):_vm._e()],1),_c('v-simple-table',{staticClass:"mt-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Item ")]),_c('th',[_vm._v("Value")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(" Order ID ")]),_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                                                                        name: _vm.$constants
                                                                            .ROUTE_NAMES
                                                                            .ORDERS_SHOW,
                                                                        params: {
                                                                            orderId:
                                                                                item.orderId,
                                                                        },
                                                                    }}},[_vm._v(_vm._s(item.orderId))]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyOrderId(
                                                                            item.orderId
                                                                        )}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.copyIcon)+" ")]),_vm._v(" Copy ")],1)],1)]),_c('tr',[_c('td',[_vm._v(" Customer Name ")]),_c('td',{domProps:{"innerHTML":_vm._f("htmlDisplayName")(item.customerName)}})]),_c('tr',[_c('td',[_vm._v(" Payment Method ")]),_c('td',[_vm._v(" "+_vm._s(item.customerPaymentMethod)+" "),(
                                                                        item.isMobilePayment
                                                                    )?_c('span',[_vm._v(" ("+_vm._s(item.customerPaymentPhoneNumber)+") ")]):_vm._e(),(
                                                                        item.hasBankCardPayment &&
                                                                        item.customerBankCardNumber
                                                                    )?_c('span',[_vm._v(" ("+_vm._s(item.customerBankCardNumber)+") ")]):_vm._e()])]),_c('tr',[_c('td',[_vm._v(" Amount Paid ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.paymentAmount, item.paymentCurrency ))+" ")])]),_c('tr',[_c('td',[(
                                                                        item.isDstv
                                                                    )?_c('span',[_vm._v(" Credits Added ")]):_vm._e(),(
                                                                        item.isCanalplus
                                                                    )?_c('span',[_vm._v(" Subscription Price ")]):_c('span',[_vm._v(" Amount ")])]),_c('td',[(
                                                                        item.isDstv
                                                                    )?_c('span',[_vm._v(" "+_vm._s(_vm._f("naira")(item.creditsAdded))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.creditsAdded))+" ")])])]),(
                                                                item.isDstv
                                                            )?_c('tr',[_c('td',[_vm._v(" Smartcard Number ")]),_c('td',[_vm._v(" "+_vm._s(item.smartCardNumber)+" ")])]):_vm._e(),(
                                                                item.isCanalplus
                                                            )?_c('tr',[_c('td',[_vm._v(" Card Number ")]),_c('td',[_vm._v(" "+_vm._s(item.smartCardNumber)+" ")])]):_vm._e(),(
                                                                item.isEneoPostpaid ||
                                                                item.isCamwater
                                                            )?_c('tr',[_c('td',[_vm._v(" Bill Number ")]),_c('td',[_vm._v(" "+_vm._s(item.billId)+" ")])]):_vm._e(),(
                                                                item.isEneoPrepaid
                                                            )?_c('tr',[_c('td',[_vm._v(" Meter Number ")]),_c('td',[_vm._v(" "+_vm._s(item.billId)+" ")])]):_vm._e(),(
                                                                item.isAirtime ||
                                                                item.isExchange
                                                            )?_c('tr',[_c('td',[_vm._v(" Recipient Phone Number ")]),_c('td',[_vm._v(" "+_vm._s(item.recipientPhoneNumber)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v(" Receipt Email ")]),_c('td',[_vm._v(" "+_vm._s(item.customerEmail)+" ")])]),(
                                                                item.isDstv
                                                            )?_c('tr',[_c('td',[_vm._v("Months")]),_c('td',[_vm._v(" "+_vm._s(item.quantity)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v(" Fulfillment ID ")]),_c('td',[_vm._v(" "+_vm._s(item.fulfillmentProviderId)+" ")])])])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"mt-4 mb-2"},[_c('h6',{staticClass:"text-h6"},[_vm._v(" Timeline ")])]),_c('v-timeline',{staticClass:"pr-4",attrs:{"dense":""}},[_vm._l((item.orderStatusChanges),function(statusChange){return _c('v-timeline-item',{key:statusChange.id,attrs:{"color":statusChange.statusColor,"small":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                                                                    statusChange.htmlMessage(
                                                                        item
                                                                    )
                                                                )}}),_vm._v(" at "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("time")(statusChange.changedAt))+" ")])])],1)],1)}),(
                                                        item.isReferred &&
                                                        item.isFulfilled
                                                    )?_c('v-timeline-item',{attrs:{"small":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text--secondary"},[_vm._v("We")]),_vm._v(" paid the referral bonus to "+_vm._s(item.referralName)+" with referral code "),_c('code',[_vm._v(_vm._s(item.referralCode))]),_vm._v(" at "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("time")(item.referralTime))+" ")])])],1)],1):_vm._e()],2)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }